import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store'
import { closeDialog, DialogType, openDialog } from 'store/dialogs.slice'
import {
  EventStatus,
  IDialog,
  IFriendInfo,
  IGetHistoryItem,
  IHistoryItem,
  IProductInfo,
  ISharingHandler,
  ISlot,
  ITimer,
  ShareObjectType,
  TabBarType,
  IRemindData,
  IItemData,
} from 'types'
import { getCookie, isItemMatch, requestTimeout } from './utils'
import {
  useAddShareMutation,
  useLazyGetFriendListQuery,
  useLazyGetShareLinkQuery,
  useShareViaChatMutation,
} from '../store/share-api.slice'
import {
  useGetActivityByCodeQuery,
  useGetEventByCodeQuery,
  useGetMallDataQuery,
  useLazyGetHistoryEndedItemsQuery,
  useLazyGetHistoryWonItemsQuery,
} from 'store/api.slice'
import { useRouter } from 'next/router'
import { getAppSignature, getDeviceID, sharingAppHandler, showWebBridgeToast } from 'shared/webBridge'
import i18next from 'i18next'
import produce from 'immer'
import { updatePersistentDialog } from './persistent'
import { setSelectingTab } from 'store/history.slice'

export const useTheme = () => {
  // get data from redux

  useEffect(() => {
    setTimeout(() => {
      document.documentElement.style.setProperty('--background', '#0046AB')
      document.documentElement.style.setProperty('--button', 'blue')
    }, 3000)
  }, [])
}

export const useDialogs = (dialogType: DialogType): IDialog => {
  const dialogData = useSelector((state: RootState) => state.dialogs?.[dialogType]) as Record<string, unknown> & {
    isOpen: boolean
  }
  const dispatch = useAppDispatch()

  const open = (payload?: Record<string, unknown>) => dispatch(openDialog({ dialogType, ...payload }))
  const close = () => dispatch(closeDialog({ dialogType }))

  return { open, close, ...dialogData }
}

export const useCurrentEventStatus = (slots: ISlot[]) => {
  const [currentEventStatus, setCurrentEventStatus] = useState<EventStatus | undefined>()

  useEffect(() => {
    let timer: ITimer
    const timeToOngoing = slots[0]?.begin_time - Date.now()
    const timeToEnd = slots[0]?.end_time - Date.now()

    if (timeToOngoing > 0) {
      setCurrentEventStatus('up-coming')
      timer = requestTimeout(() => setCurrentEventStatus('on-going'), timeToOngoing)
    } else if (timeToEnd > 0) {
      setCurrentEventStatus('on-going')
      timer = requestTimeout(() => setCurrentEventStatus('ended'), timeToEnd)
    } else setCurrentEventStatus('ended')

    return () => timer?.clear()
  }, [slots, currentEventStatus])

  return { currentEventStatus }
}

export const useGetFriendList = () => {
  const [data, setData] = useState<IFriendInfo[]>([])
  const [totalFriend, setTotalFriend] = useState<number>(0)
  const deviceIDRef = useRef('')
  const [userShareID, setUserShareID] = useState<number>(0)
  const [appSignature, setAppSignature] = useState<string>('')

  const { query, isReady } = useRouter()
  const activityCode = query.cid as string
  const eventCode = query.event as string
  const micrositeID = query.site as string
  const objectType = query['object_type'] as ShareObjectType
  const GET_FRIEND_LIST_LIMIT = 10 as number

  const [getFriendList, { data: friendData, error: friendError, isLoading: friendLoading }] =
    useLazyGetFriendListQuery()
  const [getShareLink, { data: shareLinkData, error: shareLinkError }] = useLazyGetShareLinkQuery()
  const [shareViaChat, { data: shareViaChatData, error: shareViaChatError }] = useShareViaChatMutation()
  const [addShare, { data: addShareData, error: addShareError }] = useAddShareMutation()

  useEffect(() => {
    isReady &&
      !deviceIDRef.current &&
      getDeviceID().then((value) => {
        deviceIDRef.current = value
        getFriendList({
          activityCode,
          eventCode,
          deviceID: deviceIDRef.current,
          limit: GET_FRIEND_LIST_LIMIT,
          offset: data.length,
        })
      })
  }, [isReady])

  useEffect(() => {
    // Get friendData response and push it to current data
    if (friendData && friendData.data) {
      setTotalFriend(friendData.total)
      const newData = produce(data, (draft) => {
        friendData.data.forEach((friend) => {
          const idxInData = draft.findIndex((ele) => ele.user_id === friend.user_id)
          idxInData === -1 ? draft.push(friend) : (draft[idxInData] = friend)
        })
      })
      setData(newData)
    }
  }, [friendData])

  const loadMore = () => {
    getFriendList({
      activityCode,
      eventCode,
      deviceID: deviceIDRef.current,
      limit: GET_FRIEND_LIST_LIMIT,
      offset: data.length,
    })
  }
  // These useEffects are for handling handleShareFriend function
  useEffect(() => {
    if (!userShareID) return
    if (shareLinkData) {
      const signatureData = {
        to_user_id: userShareID,
        from_user_id: parseInt(getCookie('SPC_U') || '0'),
        msg_type: 0, // MSG_TYPE_TEXT
        entrypoint: 1019, // ENTRY_POINT_GTP
      }
      getAppSignature(signatureData).then((value) => setAppSignature(value))
    } else if (shareLinkError) {
      showWebBridgeToast({ message: i18next.t('Có lỗi xảy ra. Vui lòng thử lại!'), iconType: 'failure' })
    }
  }, [userShareID, shareLinkData, shareLinkError])

  useEffect(() => {
    if (appSignature !== '' && shareLinkData && userShareID) {
      shareViaChat({ shareID: shareLinkData.share_id, micrositeID, toUserID: userShareID, appSignature })
    }
  }, [appSignature])

  useEffect(() => {
    if (!shareLinkData || !userShareID) return
    if (shareViaChatData) {
      const sharedFriendIdx = data.findIndex((friend) => friend.user_id === userShareID)
      if (sharedFriendIdx !== -1) {
        const newData = produce(data, (draft) => {
          draft[sharedFriendIdx].shared = true
        })
        setData(newData)
      }
      addShare({ shareID: shareLinkData.share_id, toUserID: userShareID })
    } else if (shareViaChatError) {
      showWebBridgeToast({ message: i18next.t('Có lỗi xảy ra. Vui lòng thử lại!'), iconType: 'failure' })
    }
  }, [shareViaChatData, shareViaChatError])

  useEffect(() => {
    if (addShareData) {
      updatePersistentDialog({ selectingEvent: eventCode, willRemovePersistentOnOpen: true })
      const { share_chance_incr: shareChanceIncr } = addShareData

      if (shareChanceIncr > 0)
        showWebBridgeToast({
          message: i18next.t(`Bạn đã nhận ${shareChanceIncr} lượt đoán giá`),
          iconType: 'success',
        })
      else showWebBridgeToast({ message: i18next.t('Đã hết lượt chia sẻ'), iconType: 'failure' })
    } else if (addShareError) {
      updatePersistentDialog({ selectingEvent: eventCode, willRemovePersistentOnOpen: true })
      showWebBridgeToast({ message: i18next.t('Có lỗi xảy ra. Vui lòng thử lại!'), iconType: 'failure' })
    }
  }, [addShareData, addShareError])

  const handleShareFriend = async (toUserID: number) => {
    setUserShareID(toUserID)
    if (!shareLinkData?.share_id) {
      getShareLink({ activityCode, eventCode, snsType: 'chat', objectType, micrositeID })
    }
  }

  return {
    data,
    isLoading: data.length === 0 && friendLoading,
    error: friendError,
    isReachingEnd: data.length >= totalFriend || totalFriend === 0,
    loadMore,
    handleShareFriend,
  }
}

export const useSharingHandler = () => {
  const [getShareLink, { data: shareLinkData, error: shareLinkError, isFetching: shareLinkFetching }] =
    useLazyGetShareLinkQuery()
  const [addShare, { data: addShareData, error: addShareError, isLoading: addShareLoading }] = useAddShareMutation()
  const [sharingParams, setSharingParams] = useState<ISharingHandler>({
    activityCode: '',
    eventCode: '',
    snsType: 'facebookLink',
    objectType: 'event',
    micrositeID: '',
  })
  const { query } = useRouter()
  const eventCode = query.event as string

  useEffect(() => {
    if (sharingParams.activityCode === '') return
    if (!shareLinkFetching && shareLinkData) {
      const {
        share_id: shareID,
        share_link: shareLink,
        share_content: shareContent,
        share_image: shareImage,
      } = shareLinkData
      sharingAppHandler(sharingParams.snsType, { url: shareLink, content: shareContent, img: shareImage }, () => {
        // onSuccess
        if (sharingParams.objectType === 'event') {
          addShare({ shareID })
        }
      })
    } else if (shareLinkError) {
      showWebBridgeToast({ message: i18next.t('Có lỗi xảy ra!'), iconType: 'failure' })
    }
  }, [shareLinkData, shareLinkError, sharingParams, shareLinkFetching])

  useEffect(() => {
    if (addShareLoading) return
    if (addShareData) {
      updatePersistentDialog({ selectingEvent: eventCode, willRemovePersistentOnOpen: true })
      const { share_chance_incr: shareChanceIncr } = addShareData

      if (shareChanceIncr > 0) {
        showWebBridgeToast({
          message: i18next.t(`Bạn đã nhận ${shareChanceIncr} lượt đoán giá`),
          iconType: 'success',
        })
      } else showWebBridgeToast({ message: i18next.t('Đã hết lượt chia sẻ'), iconType: 'failure' })
    } else if (addShareError) {
      updatePersistentDialog({ selectingEvent: eventCode, willRemovePersistentOnOpen: true })
      showWebBridgeToast({ message: i18next.t('Có lỗi xảy ra. Vui lòng thử lại!'), iconType: 'failure' })
    }
  }, [addShareData, addShareError])

  const sharingHandler = (sharingHandlerParams: ISharingHandler) => {
    setSharingParams(sharingHandlerParams)
    getShareLink(sharingHandlerParams)
  }

  return {
    sharingHandler,
  }
}

export const useSlotConfig = (): ISlot['config'] => {
  const {
    query: { cid },
  } = useRouter()
  const { data } = useGetActivityByCodeQuery(cid as string, { skip: !cid })
  const selectingEvent = useSelector((state: RootState) => state.app.selectingEvent)
  return useMemo(
    () => (data?.slots?.find((slot) => slot.event_code === selectingEvent) as ISlot)?.config ?? {},
    [selectingEvent, data?.slots],
  )
}

const DEFAULT_LIMIT = 10
export const useHistoryList = (): {
  data: IHistoryItem[]
  isLoading: boolean
  loadMore
  isFetching: boolean
  isEndedList: boolean
  selectingTab: TabBarType
  isEmpty: boolean
  updateItem: (item: IHistoryItem, index: number) => void
} => {
  const dispatch = useAppDispatch()
  const selectingTab = useSelector((state: RootState) => state.history.selectingTab)
  const [getWon, wonApiPayload] = useLazyGetHistoryWonItemsQuery()
  const [getEnded, endedApiPayload] = useLazyGetHistoryEndedItemsQuery()
  const [wonListData, setWonList] = useState<(IGetHistoryItem & { isEndedList: boolean }) | undefined>()
  const [endedListData, setEndedList] = useState<(IGetHistoryItem & { isEndedList: boolean }) | undefined>()

  const isLoading = useMemo(() => {
    return !!(wonApiPayload.isLoading && endedApiPayload.isLoading)
  }, [wonApiPayload.isLoading, endedApiPayload.isLoading])

  const isFetching = useMemo(() => {
    return !!(wonApiPayload.isFetching || endedApiPayload.isFetching)
  }, [wonApiPayload.isFetching, endedApiPayload.isFetching])

  const data = useMemo(() => {
    if (selectingTab === 'ended' && endedListData?.data) return endedListData.data
    if (selectingTab === 'won' && wonListData?.data) return wonListData.data
    return []
  }, [selectingTab, wonListData?.data, endedListData?.data]) as IHistoryItem[]

  const loadMore = useCallback(() => {
    if (isFetching) return
    if (selectingTab === 'won') {
      getWon({ limit: wonListData?.pagination?.limit ?? DEFAULT_LIMIT, offset: wonListData?.data?.length ?? 0 })
    } else
      getEnded({ limit: endedListData?.pagination?.limit ?? DEFAULT_LIMIT, offset: endedListData?.data?.length ?? 0 })
  }, [selectingTab, wonListData, endedListData, isFetching])

  const isEndedList = useMemo<boolean>(() => {
    if (selectingTab === 'ended') return !!endedListData?.isEndedList
    return !!wonListData?.isEndedList
  }, [selectingTab, wonListData?.isEndedList, endedListData?.isEndedList])

  const isEmpty = useMemo<boolean>(() => {
    if (selectingTab === 'ended') return endedListData?.total === 0
    return wonListData?.total === 0
  }, [selectingTab, wonListData?.total, endedListData?.total])

  const updateItem = (item, index) => {
    if (selectingTab === 'won') {
      setWonList(
        produce((draft) => {
          if (draft?.data) draft.data[index] = item
        }),
      )
    } else
      setEndedList(
        produce((draft) => {
          if (draft?.data) draft.data[index] = item
        }),
      )
  }

  useEffect(() => {
    getWon({ limit: DEFAULT_LIMIT, offset: 0 })
    getEnded({ limit: DEFAULT_LIMIT, offset: 0 })
  }, [])

  useEffect(() => {
    const { isFetching, data: payload } = wonApiPayload
    const { pagination = { limit: DEFAULT_LIMIT, offset: 0 }, total = 0, data } = (payload || {}) as IGetHistoryItem

    if (isFetching) return

    setWonList(
      produce((draft) => {
        if (!!draft?.data) {
          draft.data.push(...(data ?? []))
          draft.pagination = pagination
          draft.total = total

          if (draft.data.length >= draft.total) draft.isEndedList = true
        } else {
          return { ...payload, isEndedList: (payload?.total ?? 0) <= (payload?.data?.length ?? 0) }
        }
      }),
    )
  }, [wonApiPayload?.isFetching])

  useEffect(() => {
    const { isFetching, data: payload } = endedApiPayload
    const { pagination = { limit: DEFAULT_LIMIT, offset: 0 }, total = 0, data } = (payload || {}) as IGetHistoryItem

    if (isFetching) return

    setEndedList(
      produce((draft) => {
        if (!!draft?.data) {
          draft.data.push(...(data ?? []))
          draft.pagination = pagination
          draft.total = total

          if (draft.data.length >= draft.total) draft.isEndedList = true
        } else {
          return { ...payload, isEndedList: (payload?.total ?? 0) <= (payload?.data?.length ?? 0) }
        }
      }),
    )
  }, [endedApiPayload?.isFetching])

  useEffect(() => {
    if (!isLoading && wonListData?.total === 0) {
      dispatch(setSelectingTab('ended'))
    }
  }, [isLoading, wonListData])

  return { data, isLoading, loadMore, isFetching, isEndedList, selectingTab, isEmpty, updateItem }
}

export const useGetSelectingEventStatus = () => {
  const selectingEvent = useSelector((state: RootState) => state.app.selectingEvent)
  const currentEventStatus = useSelector((state: RootState) => state.app.currentEventStatus)
  const router = useRouter()
  const {
    query: { cid },
  } = router

  const { data } = useGetActivityByCodeQuery(cid as string, {
    skip: !cid,
  })

  const selectingEventStatus: EventStatus = useMemo(() => {
    if (!data || !selectingEvent || !currentEventStatus) return 'up-coming'
    if (selectingEvent === data.current_event_code) return currentEventStatus
    return 'up-coming'
  }, [selectingEvent, currentEventStatus, data])

  return selectingEventStatus
}

export const useGetItemListData = () => {
  const {
    query: { cid },
  } = useRouter()
  const selectingEvent = useSelector((state: RootState) => state.app.selectingEvent)
  const isLogin = useSelector((state: RootState) => state.app.isLogin)
  const selectingEventStatus = useGetSelectingEventStatus()

  const { data: eventData } = useGetEventByCodeQuery(
    {
      activityCode: cid as string,
      eventCode: selectingEvent as string,
      includedUserState: isLogin && selectingEventStatus === 'on-going',
    },
    {
      skip: !cid || !selectingEvent || selectingEventStatus === 'ended',
    },
  )

  const { data: mallData } = useGetMallDataQuery(
    {
      items:
        eventData?.items?.map(
          (item) =>
            ({
              shop_id: item.shop_id,
              item_id: item.item_id,
              model_id: item.model_id,
            } as IProductInfo),
        ) || [],
    },
    { skip: !eventData },
  )

  const data = useMemo(
    () =>
      eventData?.items?.map((item) => {
        const mallItem = mallData?.items?.find((mallItem) => isItemMatch(item, mallItem))
        return { ...item, mallData: { ...mallItem?.data } } as IItemData
      }),
    [eventData, mallData],
  )

  return data
}

export const useGetRemindData = () => {
  const {
    query: { cid },
  } = useRouter()

  const { data } = useGetActivityByCodeQuery(cid as string, {
    skip: !cid,
    // refetchOnFocus: true,
  })
  const selectingEvent = useSelector((state: RootState) => state.app.selectingEvent)

  const remindData = useMemo(() => {
    const selectingEventData =
      (data?.slots?.find((slot) => slot.event_code === selectingEvent) as ISlot) || data?.slots?.[0]
    return {
      beginTime: selectingEventData?.begin_time,
      endTime: selectingEventData?.end_time,
      reminderTitle: selectingEventData?.config.text_reminder_title,
      reminderContent: selectingEventData?.config.text_reminder_content,
    } as IRemindData
  }, [selectingEvent, data])

  return remindData
}
